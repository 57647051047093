<template>
  <div class="page-slideout">
    <div class="section section-separate">
      <div class="container">
        <form class="content register-content">
          <div class="nav-responsive">
            <ul class="nav nav-register">
              <template v-for="(item, index) in itemsStep">
                <li
                  :key="index"
                  :class="
                    (item.active && 'active') ||
                    (item.finish && 'navtop-canclick')
                  "
                  @click="
                    item.finish &&
                      stepwh_signup < 8 &&
                      changeStep(item.linkItems)
                  "
                >
                  <a href="" class="d-flex flex-column">
                    <span v-if="item.finish" class="icons"
                      ><img src="@/assets/icons/icon-checkmark.svg" alt=""
                    /></span>
                    <span class="text">{{ item.name }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
          <div class="scroll-wrapper scrollbar-inner has-menu overflow-y-auto">
            <div class="form-box">
              <h3 class="title-md">
                {{ $t("SignupStep3.all_rateplan_type") }}
              </h3>

              <div class="form-group pt-2">
                <label class="light">{{
                  $t("SignupStep3.rateplan_type")
                }}</label>
                <table class="table table-roomlist">
                  <template v-for="(item, index) in roomData.rateplans">
                    <tr :key="index">
                      <th>
                        {{ checkRoomName(item) }} ({{
                          `${
                            item.per_room
                              ? $t("SignupStep3.pricepernight")
                              : $t("SignupStep3.priceperperson")
                          }`
                        }})
                      </th>
                      <td class="col-edit">
                        <a
                          @mouseenter="item.hovered = true"
                          @mouseleave="item.hovered = false"
                          @click="gotoupdate(item)"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            id="icon-edit"
                          >
                            <g clip-path="url(#clip0)">
                              <path
                                d="M9.91241 20.0004C9.80324 20.0004 9.69658 19.9571 9.61741 19.8787C9.51908 19.7804 9.47574 19.6387 9.50324 19.5029L10.0924 16.5571C10.1091 16.4754 10.1474 16.4012 10.2066 16.3437L16.6882 9.86207C17.1749 9.3754 17.9691 9.3754 18.4557 9.86207L19.6341 11.0404C20.1216 11.5279 20.1216 12.3204 19.6341 12.8079L13.1524 19.2896C13.0949 19.3479 13.0207 19.3871 12.9391 19.4037L9.99324 19.9929C9.96658 19.9979 9.93908 20.0004 9.91241 20.0004ZM10.8857 16.8429L10.4441 19.0521L12.6532 18.6104L19.0457 12.2179C19.2082 12.0554 19.2082 11.7912 19.0457 11.6287L17.8674 10.4504C17.7049 10.2879 17.4407 10.2879 17.2782 10.4504L10.8857 16.8429ZM12.8582 18.9946H12.8666H12.8582Z"
                                :fill="item.hovered ? '#EE3068' : '#333333'"
                              />
                              <path
                                d="M7.91667 17.5H2.08333C0.934167 17.5 0 16.5658 0 15.4167V4.58333C0 3.43417 0.934167 2.5 2.08333 2.5H3.75C3.98 2.5 4.16667 2.68667 4.16667 2.91667C4.16667 3.14667 3.98 3.33333 3.75 3.33333H2.08333C1.39417 3.33333 0.833333 3.89417 0.833333 4.58333V15.4167C0.833333 16.1058 1.39417 16.6667 2.08333 16.6667H7.91667C8.14667 16.6667 8.33333 16.8533 8.33333 17.0833C8.33333 17.3133 8.14667 17.5 7.91667 17.5Z"
                                :fill="item.hovered ? '#EE3068' : '#333333'"
                              />
                              <path
                                d="M13.75 10C13.52 10 13.3333 9.81333 13.3333 9.58333V4.58333C13.3333 3.89417 12.7725 3.33333 12.0833 3.33333H10.4167C10.1867 3.33333 10 3.14667 10 2.91667C10 2.68667 10.1867 2.5 10.4167 2.5H12.0833C13.2325 2.5 14.1666 3.43417 14.1666 4.58333V9.58333C14.1666 9.81333 13.98 10 13.75 10Z"
                                :fill="item.hovered ? '#EE3068' : '#333333'"
                              />
                              <path
                                d="M9.58398 5H4.58398C3.89482 5 3.33398 4.43917 3.33398 3.75V2.08333C3.33398 1.85333 3.52065 1.66667 3.75065 1.66667H5.04232C5.23565 0.716667 6.07815 0 7.08398 0C8.08982 0 8.93232 0.716667 9.12565 1.66667H10.4173C10.6473 1.66667 10.834 1.85333 10.834 2.08333V3.75C10.834 4.43917 10.2732 5 9.58398 5ZM4.16732 2.5V3.75C4.16732 3.97917 4.35482 4.16667 4.58398 4.16667H9.58398C9.81315 4.16667 10.0007 3.97917 10.0007 3.75V2.5H8.75065C8.52065 2.5 8.33398 2.31333 8.33398 2.08333C8.33398 1.39417 7.77315 0.833333 7.08398 0.833333C6.39482 0.833333 5.83398 1.39417 5.83398 2.08333C5.83398 2.31333 5.64732 2.5 5.41732 2.5H4.16732Z"
                                :fill="item.hovered ? '#EE3068' : '#333333'"
                              />
                              <path
                                d="M11.25 7.49936H2.91667C2.68667 7.49936 2.5 7.31269 2.5 7.08269C2.5 6.85268 2.68667 6.66602 2.91667 6.66602H11.25C11.48 6.66602 11.6666 6.85268 11.6666 7.08269C11.6666 7.31269 11.48 7.49936 11.25 7.49936Z"
                                :fill="item.hovered ? '#EE3068' : '#333333'"
                              />
                              <path
                                d="M11.25 9.99934H2.91667C2.68667 9.99934 2.5 9.81267 2.5 9.58268C2.5 9.35268 2.68667 9.16602 2.91667 9.16602H11.25C11.48 9.16602 11.6666 9.35268 11.6666 9.58268C11.6666 9.81267 11.48 9.99934 11.25 9.99934Z"
                                :fill="item.hovered ? '#EE3068' : '#333333'"
                              />
                              <path
                                d="M11.25 12.4993H2.91667C2.68667 12.4993 2.5 12.3127 2.5 12.0827C2.5 11.8527 2.68667 11.666 2.91667 11.666H11.25C11.48 11.666 11.6666 11.8527 11.6666 12.0827C11.6666 12.3127 11.48 12.4993 11.25 12.4993Z"
                                :fill="item.hovered ? '#EE3068' : '#333333'"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                      </td>
                      <td class="col-delete">
                        <a
                          @mouseenter="item.hoverdel = true"
                          @mouseleave="item.hoverdel = false"
                          @click="removedata(item)"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0)">
                              <path
                                d="M12.7584 7.24609C12.4997 7.24609 12.29 7.45577 12.29 7.71448V16.5669C12.29 16.8255 12.4997 17.0353 12.7584 17.0353C13.0171 17.0353 13.2268 16.8255 13.2268 16.5669V7.71448C13.2268 7.45577 13.0171 7.24609 12.7584 7.24609Z"
                                :fill="item.hoverdel ? '#EE3068' : '#333333'"
                              />
                              <path
                                d="M7.23206 7.24609C6.97335 7.24609 6.76367 7.45577 6.76367 7.71448V16.5669C6.76367 16.8255 6.97335 17.0353 7.23206 17.0353C7.49076 17.0353 7.70044 16.8255 7.70044 16.5669V7.71448C7.70044 7.45577 7.49076 7.24609 7.23206 7.24609Z"
                                :fill="item.hoverdel ? '#EE3068' : '#333333'"
                              />
                              <path
                                d="M3.20431 5.95419V17.4942C3.20431 18.1762 3.45442 18.8168 3.89133 19.2764C4.32623 19.7373 4.93147 19.9989 5.56488 20H14.4268C15.0604 19.9989 15.6657 19.7373 16.1004 19.2764C16.5373 18.8168 16.7874 18.1762 16.7874 17.4942V5.95419C17.6559 5.72366 18.2187 4.8846 18.1025 3.99339C17.9862 3.10236 17.2271 2.43583 16.3284 2.43565H13.9303V1.85017C13.933 1.35782 13.7383 0.885049 13.3898 0.537238C13.0413 0.18961 12.5678 -0.00396362 12.0754 6.15416e-05H7.91631C7.42396 -0.00396362 6.95045 0.18961 6.60191 0.537238C6.25337 0.885049 6.0587 1.35782 6.06144 1.85017V2.43565H3.66336C2.76465 2.43583 2.00554 3.10236 1.88918 3.99339C1.773 4.8846 2.33579 5.72366 3.20431 5.95419ZM14.4268 19.0632H5.56488C4.76406 19.0632 4.14107 18.3753 4.14107 17.4942V5.99536H15.8506V17.4942C15.8506 18.3753 15.2277 19.0632 14.4268 19.0632ZM6.99821 1.85017C6.9951 1.60628 7.09097 1.37154 7.26405 1.19938C7.43695 1.02721 7.67224 0.932619 7.91631 0.936827H12.0754C12.3195 0.932619 12.5548 1.02721 12.7277 1.19938C12.9007 1.37136 12.9966 1.60628 12.9935 1.85017V2.43565H6.99821V1.85017ZM3.66336 3.37242H16.3284C16.794 3.37242 17.1714 3.74987 17.1714 4.21551C17.1714 4.68114 16.794 5.05859 16.3284 5.05859H3.66336C3.19772 5.05859 2.82027 4.68114 2.82027 4.21551C2.82027 3.74987 3.19772 3.37242 3.66336 3.37242Z"
                                :fill="item.hoverdel ? '#EE3068' : '#333333'"
                              />
                              <path
                                d="M9.99572 7.24609C9.73702 7.24609 9.52734 7.45577 9.52734 7.71448V16.5669C9.52734 16.8255 9.73702 17.0353 9.99572 17.0353C10.2544 17.0353 10.4641 16.8255 10.4641 16.5669V7.71448C10.4641 7.45577 10.2544 7.24609 9.99572 7.24609Z"
                                :fill="item.hoverdel ? '#EE3068' : '#333333'"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>

              <div class="buttons pt-3">
                <button
                  class="btn w-100 btn-addtype"
                  type="button"
                  @click="addTypeFunc()"
                  :disabled="!canaddrateplans"
                >
                  <span style="text-transform: none">{{
                    $t("SignupStep3.btn_add")
                  }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="form-buttons">
            <div
              class="pa-0 d-flex flex-row align-center justify-space-between flex-100per"
            >
              <button
                type="button"
                class="btn-back gl-bt hover_btn ml-0"
                style="text-transform: capitalize !important"
                @click="goback()"
              >
                <span>{{ `< ${$t("servicepage.back")}` }}</span>
              </button>

              <button
                type="button"
                class="btn gl-bt hover_btn mx-0"
                style="text-transform: capitalize !important"
                :disabled="!cangonextstep"
                @click="nextstep(4)"
              >
                <span>{{ $t("SignupStep3.btn_next") }}</span>
              </button>
            </div>
          </div>
        </form>
        <div class="sidebar">
          <div class="inner d-flex">
            <a
              :href="pathrightphoto"
              class="img-exemple iphone-model d-flex flex-column align-center justify-center"
              data-fancybox
            >
              <span class="btn-open-picture">{{
                $t("SignupStep4.clickopenpicture")
              }}</span>
              <img :src="pathrightphoto" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  name: "RatePlanScreen",
  metaInfo: {
    title: "Rate plans and vouchers",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    stepwh_signup: 3,
    addRatePlans: false,
    updateRatePlans: false,
    addRatePlanType: false,
    selectRatePlanData: {},
    roomData: {
      types: [],
      rateplans: [],
    },
    itemsRatePlanType: [],
    propertyID: null,
    itemsStep: [],
    pathrightphoto: "",
    cangonextstep: false,
    canaddrateplans: true,
    userToken: "",
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    if (self.userToken && self.propertyID) {
      self.changeLanguageData();
    } else {
      self.gotosignin();
    }
    if (
      (localStorage.getItem("Token") == "" || !localStorage.getItem("Token")) &&
      self.userToken
    ) {
      localStorage.setItem("Token", self.userToken);
    }
    localStorage.setItem("signupstep", self.stepwh_signup);
    localStorage.setItem("SignupStep", self.stepwh_signup);
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.changeLanguageData);
    this.colorHover();
  },
  watch: {
    "roomData.rateplans"() {
      const self = this;
      if (self?.roomData?.rateplans?.length) self.cangonextstep = true;
      else self.cangonextstep = false;
      self.checkcanaddnewrateplanFunc();
    },
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    addTypeFunc() {
      const self = this;
      var item = {
        link: `signup/rateplan/${self.propertyID}/create`,
        name: "SignupCreateRatePlaneScreen",
        params: { propertyId: self.propertyID },
      };
      self.$router.push({ name: item.name, params: item.params });
      EventBus.$emit("sendStep3Create", true);
    },
    changeLanguageData() {
      const self = this;
      self.initRatePlanData();
      self.initRatePlanTypes();
      self.checkcanaddnewrateplanFunc();
    },
    checkRoomName(data) {
      const self = this;
      const temp = self?.itemsRatePlanType?.find(
        (p) => p.value == data.rate_plan
      );
      // console.log(data, temp);
      return temp?.name || data?.name?.value || data?.name || "";
    },
    checkcanaddnewrateplanFunc() {
      const self = this;
      if (
        self?.roomData?.rateplans?.length ==
        self?.itemsRatePlanType?.length * 2
      )
        self.canaddrateplans = false;
      else self.canaddrateplans = true;
    },
    async initRatePlanTypes() {
      const self = this;
      var temp = [];
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + "/setting/rate_plan",
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          res?.data?.result?.map((el) => {
            temp.push({
              _id: el._id,
              name: el.value,
              value: el._id,
            });
          });
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemsRatePlanType = temp;
        self.checkcanaddnewrateplanFunc();
      }
    },
    async initRatePlanData() {
      const self = this;
      var temp = {};
      var items = [];
      EventBus.$emit("loadingPage");
      self.roomData.rateplans = [];
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0];
          if (res?.data?.total) {
            temp?.rate_plan_price?.map((el) => {
              // console.log(el)
              if (el?.rooms?.length) {
                items.push({
                  ...el,
                  hovered: false,
                  hoverdel: false,
                  name: el?.rate_plan || el?.name || "",
                });
              }
            });
            self.itemsStep = [
              {
                name: self.$t("SignupStep.step1"),
                active: (self.stepwh_signup == 1 && true) || false,
                finish: temp?.step_signup?.step1 || false,
                linkItems: {
                  link: `signup/basic/${self.userToken}/${self.propertyID}`,
                  name: "SignupBasicInformationScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 1,
              },
              {
                name: self.$t("SignupStep.step2"),
                active: (self.stepwh_signup == 2 && true) || false,
                finish: temp?.step_signup?.step2 || false,
                linkItems: {
                  link: `signup/room/${self.userToken}/${self.propertyID}`,
                  name: "SignupRoomScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 2,
              },
              {
                name: self.$t("SignupStep.step3"),
                active: (self.stepwh_signup == 3 && true) || false,
                finish: temp?.step_signup?.step3 || false,
                linkItems: {
                  link: `signup/rateplan/${self.userToken}/${self.propertyID}`,
                  name: "SignupRatePlanScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 3,
              },
              {
                name: self.$t("SignupStep.step4"),
                active: (self.stepwh_signup == 4 && true) || false,
                finish: temp?.step_signup?.step4 || false,
                linkItems: {
                  link: `signup/facilities/${self.userToken}/${self.propertyID}`,
                  name: "SignupFacilitiesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 4,
              },
              {
                name: self.$t("SignupStep.step5"),
                active: (self.stepwh_signup == 5 && true) || false,
                finish: temp?.step_signup?.step5 || false,
                linkItems: {
                  link: `signup/pictures/${self.userToken}/${self.propertyID}`,
                  name: "SignupPicturesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 5,
              },
              {
                name: self.$t("SignupStep.step6"),
                active: (self.stepwh_signup == 6 && true) || false,
                finish: temp?.step_signup?.step6 || false,
                linkItems: {
                  link: `signup/policy/${self.userToken}/${self.propertyID}`,
                  name: "SignupPolicyScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 6,
              },
              {
                name: self.$t("SignupStep.step7"),
                active: (self.stepwh_signup == 7 && true) || false,
                finish: temp?.step_signup?.step7 || false,
                linkItems: {
                  link: `signup/payment/${self.userToken}/${self.propertyID}`,
                  name: "SignupPaymentScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 7,
              },
            ];
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        // self.roomData.rateplans = items
        self.initDefaultRatePlanDatatypies(items);
        self.getStepData(self.itemsStep);
        self.checkcanaddnewrateplanFunc();
      }
    },
    initDefaultRatePlanDatatypies(item) {
      const self = this;
      var temp = [];
      var tempRoomTypies = [];
      if (item?.length) {
        item?.map((el) => {
          // console.log("eleleleleel: ", el);
          tempRoomTypies = [];
          el?.rooms?.map((ele) => {
            tempRoomTypies.push({
              _id: ele._id,
              room:
                typeof ele?.room == "object"
                  ? ele?.room?._id || ""
                  : ele?.room || "",
              room_name:
                typeof ele?.room_name == "object"
                  ? ele?.room_name?._id || ""
                  : ele?.room_name || "",
              base_rate: ele.base_rate,
              extra_bed_adult: ele.extra_bed_adult,
              extra_bed_child: ele.extra_bed_child,
              adult_rate: ele.adult_rate,
              child_rate: ele.child_rate,
            });
          });
          if (el?.rooms?.length)
            temp.push({
              _id: el._id,
              rate_plan:
                typeof el?.rate_plan == "object"
                  ? el?.rate_plan?._id || ""
                  : el?.rate_plan || "",
              per_room: el.per_room,
              per_guest: el.per_guest,
              room_typies: tempRoomTypies,
              name: el?.name || "",
            });
        });
      }
      self.roomData.rateplans = temp;
      // console.log(self.roomData.rateplans);
    },
    getStepData(items) {
      const self = this;
      self.itemsStep = items;
      self.changePhotoRight();
    },
    changePhotoRight() {
      const self = this;
      var tempLG = localStorage.getItem("language") === "th" ? "th" : "en";
      self.pathrightphoto = `${process.env.VUE_APP_PIC_API}/src/step3-${tempLG}.gif`;
    },
    gotoupdate(item) {
      const self = this;
      var item = {
        link: `signup/rateplan/${self.propertyID}/update/${item._id}`,
        name: "SignupUpdateRatePlaneScreen",
        params: { propertyId: self.propertyID, rateplanId: item._id },
      };
      self.$router.push({ name: item.name, params: item.params });
    },
    removedata(item) {
      const self = this;
      self.updateRatePlanTypes(item);
    },
    async updateRatePlanTypes(item) {
      const self = this;
      try {
        const res = await self.axios.delete(
          process.env.VUE_APP_API +
            `/property/rate_plan_price?rate_plan_price_id=${item._id}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          var index = self.roomData.rateplans.findIndex(
            (p) => p._id == item._id
          );
          self.roomData.rateplans.splice(index, 1);
          EventBus.$emit("loadingPage");
        } else {
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        self.initRatePlanData();
      }
    },
    async nextstep(num) {
      const self = this;
      const linkItems = {
        link: `signup/facilities/${self.propertyID}`,
        name: "SignupFacilitiesScreen",
        params: { propertyId: self.propertyID },
      };
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/step3?property_id=${self.propertyID}&next=${1}`,
          null,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          EventBus.$emit("changePathname", linkItems);
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            res?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    async changeStep(linkItems) {
      const self = this;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/step3?property_id=${self.propertyID}&next=${0}`,
          null,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          EventBus.$emit("changePathname", linkItems);
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            res?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    gotosignin() {
      var item = { link: "signin", name: "SigninScreen" };
      EventBus.$emit("changePathname", item);
    },
    goback() {
      const self = this;
      var item = {
        link: `signup/room/${self.userToken}/${self.propertyID}`,
        name: "SignupRoomScreen",
        params: { userToken: self.userToken, propertyId: self.propertyID },
      };
      EventBus.$emit("changePathname", item);
    },
  },
};
</script>

<style lang="scss" scoped>
a:hover {
  color: var(--pink) !important;
}

.v-application a:hover {
  color: var(--pink) !important;
}

.btn.btn-addtype:hover {
  background-color: #ea084a !important;
  border-color: #ea084a !important;
}

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.btn.btn-addtype:hover {
  background-color: #ea084a !important;
  border-color: #ea084a !important;
}

.title-green {
  color: var(--green) !important;
}

.title-gray {
  color: var(--gray) !important;
}

.navtop-canclick {
  cursor: pointer !important;
}

.btn[disabled] {
  height: fit-content !important;
}
</style>

<style>
@media (min-width: 768px) and (max-width: 1024px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 7.5rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
@media (min-width: 321px) and (max-width: 767px) {
  .siq_bR {
    width: 4.5rem !important;
    height: 3rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 4.5rem !important;
    height: 3rem !important;
    object-fit: contain !important;
  }
}
@media (max-width: 320px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
</style>
